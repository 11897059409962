.footer {
  width: 100%;
  height: 100px;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 20px;
  margin-top: auto;
}

.socialMedia svg {
  color: rgb(0, 0, 0);
  margin: 20px;
  font-size: 20px;
  cursor: pointer;
}

.socialMedia a {
  text-decoration: none;
}

.footer p {
  color: rgb(0, 0, 0);
  white-space: pre-wrap;
}
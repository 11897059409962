.blog-posts {
  width: 80%;
  padding: 1rem;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .blog-posts {
    width: 100%;
  }
}

.blog-posts h1 {
  text-align: center;
  border-bottom: 1px solid black;
  padding-bottom: 1rem;
  margin-bottom: 2rem;
}

.bloglist {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.blogItem {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid black;
  margin-bottom: 30px;
  padding-bottom: 20px;
}

.blogItem:last-child {
  border-bottom: none;
}

.blogItem h2 {
  margin-top: 0;
}

.blogItem p {
  margin-bottom: 0;
  line-height: 1.5;
}

.blog-post {
  padding: 1rem;
  text-align: center;
}

.buttons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  font-family: Arial, sans-serif;
}

.buttons button {
  margin-right: 10px;
  font-size: 18px;
  padding: 10px 20px;
  border-radius: 5px;
  background-color: #4caf50;
  color: white;
  border: none;
  cursor: pointer;
}

.buttons button:hover {
  background-color: #45a049;
}

.buttons button:focus {
  outline: none;
}

.projectItem:hover h2 {
  color: cyan;
}
h1 {
  text-align: center;
  margin-bottom: 20px;
}

div {
  margin-bottom: 20px;
}

img {
  display: block;
  max-width: 100%;
  margin: 0 auto;
  margin-bottom: 20px;
}

p {
  text-indent: 1em;
  text-align: justify;
}


.project {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.project h1 {
  font-size: 32px;
  margin-bottom: 10px;
}

.project img {
  display: block;
  max-width: 100%;
  margin: 0 auto;
  width: 500px;
  height: 500px;
  margin-bottom: 20px;
  object-fit: contain;
}

.project p {
  font-size: 16px;
  line-height: 1.5;
  word-wrap: break-word;
}
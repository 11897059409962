.home {
  display: flex;
  flex-direction: row;
  background-color: transparent;
  margin-top: 100px;
  height: 300px;
  margin-left: 50px;
  margin-right: 50px;
  position: relative;
}

.svg-background {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-image: url('../assets/wave.svg');
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
}

.home-content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-right: 5rem;
}

.home-content h1 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 900;
  font-size: 72px;
  line-height: 80px;
  letter-spacing: -0.04em;
  margin-top: -2rem;
}

.home-content h2 {
  margin-top: 1rem;
  font-family: 'Roboto', sans-serif;
  font-weight: normal;
  font-size: 30px;
  line-height: 50px;
  letter-spacing: -0.02em;
  color: #666;
}

.home-content p {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  margin-top: -1rem;
  color: #666;
}

.home-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-image img {
  border-radius: 100%;
  width: 100%;
  height: 100%;
  max-width: 200px;
  height: auto;
}

@media screen and (max-width: 1050px) {
  .home {
    flex-direction: column;
    height: auto;
    margin-left: 0;
    margin-right: 0;
  }

  .home-content {
    margin: 0;
    margin-top: 3rem;
  }

  .home-image {
    order: -1;
  }
}

@media screen and (max-width: 650px) {
  .home-content h1 {
    font-size: 48px;
    line-height: 60px;
  }

  .home-content p {
    font-size: 16px;
    line-height: 24px;
  }
}

@media screen and (max-width: 490px) {
  .home-content h1 {
    font-size: 36px;
    line-height: 48px;
  }

  .home-content p {
    font-size: 14px;
    line-height: 24px;
  }
}
.App {
  text-align: center;
}

/* 
body {
  padding: 0;
  margin: 0;
} */

body {
  font: 16px/1 "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #666;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  background: white;

}
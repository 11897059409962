.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.links {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
}

.links a {
  margin: 0 20px;
  text-decoration: none;
  color: #333333;
  font-weight: 500;
}

.links a:hover {
  color: #666666;
}


.toggleButton {
  display: none;
}

.left {
  display: flex;
  align-items: center;
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0 20px;
}


.cta {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 150px;
  border-radius: 20px;
  background-color: #1e90ff;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
}

.cta a {
  color: white;
  text-decoration: none;
}

.cta:hover {
  background-color: #0066cc;
}

/* Navbar CSS for mobile devices */
@media only screen and (max-width: 600px) {


  .left {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .toggleButton {
    display: flex;
    justify-content: flex-end;
  }

  .links {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
    width: 100%;
    position: absolute;
    top: 70px;
    left: 0;
    background-color: #fff;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    opacity: 0;
    visibility: hidden;
    transition: all 0.2s ease-in-out;
    z-index: 2;
  }

  #open .links {
    opacity: 1;
    visibility: visible;
  }

  #close .links {
    opacity: 0;
    visibility: hidden;
  }

  .links a {
    font-size: 24px;
    margin: 20px 0;
  }

  .toggleButton button {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    justify-content: right;
  }
}

@media only screen and (max-width: 400px) {
  .navbar {
    flex-direction: column;
    align-items: center;
    height: auto;
  }

  .left {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .left h3 {
    margin: 0;
  }

  .header {
    order: 1;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }

  .toggleButton {
    order: 3;
    display: flex;
    justify-content: flex-end;
  }

  .links {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 90px;
    align-items: center;
    height: auto;
    width: 100%;
    position: absolute;
    top: 70px;
    left: 0;
    background-color: #fff;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    opacity: 0;
    visibility: hidden;
    transition: all 0.2s ease-in-out;
    z-index: 2;
    transform: translateX(-8%)
  }

  #open .links {
    opacity: 1;
    visibility: visible;
  }

  #close .links {
    opacity: 0;
    visibility: hidden;
  }

  .links a {
    font-size: 24px;
    margin: 20px 0;
  }

  .toggleButton button {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    justify-content: right;
  }
}